import { Component, inject } from '@angular/core'
import { BaseComponent } from './common/components/base.component'
import { SpinnerService } from './common/services/spinner.service'
import { filter, fromEvent } from 'rxjs'
import { BrowserPlatformLocation } from '@angular/common'
import { NavigationEnd } from '@angular/router'
import { WindowUnloadDirective } from './common/directives/window-unload.directive'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  hostDirectives: [WindowUnloadDirective]
})

export class AppComponent extends BaseComponent {
  showLoader = false
  #spinnerService = inject(SpinnerService)
  platformLocation = inject(BrowserPlatformLocation)

  backButtonDialog = false
  
  constructor() {
    super()
    this.#spinnerService.loadingSub.subscribe({
      next: (value) => {
        this.showLoader = value
      },
    })

    this.subscriptions.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
        this.setTitle(undefined)
        this.platformLocation.pushState(null, '', null)
    })
    )
    this.subscriptions.add(fromEvent(window, 'popstate').subscribe(() => {
      console.log('again', this.router.url);
        this.platformLocation.pushState(null, '', null)
        this.backButtonDialog = true
    }))
  }

}