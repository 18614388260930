<div class="py-1 px-1 md:py-2 md:px-5 text-center" *ngIf="clockInResponse">
    <div class="flex flex-column relative border-1 border-black-alpha-10 border-round-xl p-2">
        <div class="header-bg-color text-white border-round-xl text-lg font-semibold py-2">
            <span>Time Entry</span>
        </div>
        <p-divider align="center">
            <span class="font-semibold">Location Information</span>
        </p-divider>
        <div class="flex flex-column px-2 gap-3">
            <div>
                <span>
                    If no retailer/store is detected below please make sure you are at least within 800 feet of the
                    retailer/store.
                </span>
            </div>
            <div class="flex flex-column gap-2">
                <div>
                    <span class="font-semibold">Retailer:</span>
                </div>
                <div>
                    <span class="text-lg">
                        @if (clockInResponse.RetailerName?.length > 0) {
                        {{clockInResponse.RetailerName}}
                        }
                        @else{
                        Not In Range
                        }
                    </span>
                </div>
            </div>
            <div class="flex flex-column gap-2">
                <div>
                    <span class="font-semibold">Store:</span>
                </div>
                <div>
                    <span class="text-lg">
                        <span class="text-lg">
                            @if (clockInResponse.StoreName?.length > 0) {
                            {{clockInResponse.StoreName}}
                            }
                            @else{
                            Not In Range
                            }
                        </span>
                    </span>
                </div>
            </div>
            <div>
                <button class="border-round-md" icon="pi pi-refresh" [outlined]="true"
                    [label]="'REFRESH CURRENT LOCATION'" pButton pRipple type="button" (click)="getClockinTimes()">
                </button>
            </div>
        </div>
        
            <div>
                <p-divider align="center">
                    <span class="font-semibold">Check in/Out</span>
                </p-divider>
            </div>
            <div>
                <div class="flex flex-column gap-3">
                    <div class="flex-1">
                        <span class="m-0 text-lg">
                            {{ today | date : 'mediumDate' }}
                        </span>
                    </div>
                    @if (clockInTimes.length > 0) {
                    <div class="flex-1 text-xs">
                        <div class="flex flex-row gap-2 justify-content-center font-semibold mb-1 md:mb-2">
                            <span class="clockin-header md:w-2 w-2 py-1">Login</span>
                            <span class="clockin-header md:w-2 w-2 py-1">Logout</span>
                        </div>

                        @for (item of clockInTimes; track $index) {
                        <div class="flex flex-row gap-2 justify-content-center font-semibold mb-2">
                            <span class="md:w-2 clockin-time w-2 py-1 border-1"> {{ item.ClockIn | date : 'shortTime'
                                }}</span>
                            <span class="md:w-2 clockin-time w-2 py-1" [ngClass]="{'border-1' : item.ClockOut}"> {{
                                item.ClockOut| date : 'shortTime'
                                }}</span>
                        </div>
                        }
                    </div>
                    }
                    <div class="flex-1 checked">
                        <span class="m-0" [ngClass]="{'checked-out' : !isCheckedIn, 'checked-in' : isCheckedIn}">
                            {{ isCheckedIn ? 'You are currently checked in' : 'You are currently checked out'}}
                        </span>
                    </div>
                    <div class="flex-1">
                        <div class="flex justify-content-center">
                            <div class="px-2">
                                <button class="p-button-rounded w-19rem"
                                    [label]="!isCheckedIn ? 'CHECK IN' : 'CHECK OUT'" pButton pRipple type="button"
                                    (click)="onCheckinClicked()" [disabled]="!clockInResponse.IsClockinEnabled && !isCheckedIn">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span class="checked-out font-bold text-xs" *ngIf="!isCheckedIn">
                        Must be within 800 feet of the store to check in
                    </span>
                </div>
            </div>
    </div>
</div>