<p-toast position="top-right" key="maintoast"></p-toast>
<!-- <app-spinner></app-spinner> -->
<app-layout [logoUrl]="'assets/images/logo/saraplus_logo_menu.png'">
  <router-outlet></router-outlet>
</app-layout>
<p-scrollTop [threshold]="100" icon="pi pi-arrow-up" styleClass="right-0 ml-2"></p-scrollTop>
<sp-overlay [blocked]="showLoader">
  <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
</sp-overlay>
<router-outlet name="standalone"></router-outlet>

<p-dialog header="Browser Navigation Not Supported" styleClass="w-full md:w-6 lg:w-4 back-modal" headerStyleClass="'text-center'" [(visible)]="backButtonDialog"
  appendTo="body" [modal]="true" [blockScroll]="true" [closeOnEscape]="true">
  <div class="flex flex-column mb-3">
      <span>Please do not use browser tools to process this request. If you need to cancel the order, use the designated buttons provided within SARA Plus. This ensures accuracy and proper tracking of all actions. Thank you!</span>
  </div>
  <div class="flex justify-content-end">
    <p-button label="OK" styleClass="p-button-raised" (click)="backButtonDialog = false"></p-button>
  </div>
</p-dialog>