import { Directive, HostListener } from '@angular/core';

@Directive({
  standalone: true
})
export class WindowUnloadDirective {

  constructor() { }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    const elements = document.getElementsByClassName('back-modal')
    if (elements.length > 0) {
      event.preventDefault();
      return false
    }
    return true
  }

  @HostListener('window:unload', ['$event'])
  onUnload(event: Event) {
    const elements = document.getElementsByClassName('back-modal')
    if (elements.length > 0) {
      event.preventDefault();
      return false
    }
    return true
  }
}
